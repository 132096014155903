<template>
    <van-pull-refresh v-model="isPullRefresh" success-text="刷新成功" @refresh="onRefresh">
        <div class="message-bar">
            <!-- 自定义头部 -->
            <header-top />
            <div class="content">
                <div class="item" v-for="(item,index) in itemData" :key="index">
                    <div class="message flex" @click="goMessageList(index)">
                        <div class="img">
                            <img :src="item.img" alt="" />
                            <div class="red-spot" v-show="noticeMerge[item.type]>0">
                                {{noticeMerge[item.type]}}
                            </div>
                        </div>
                        <div class="text-box">
                            <h3>{{item.title}}</h3>
                            <h4>{{item.content}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </van-pull-refresh>
</template>

<script>
import HeaderTop from '@/layout/header';
import { getNoticeMerge } from "@/api/message";
export default {
    components: {
        HeaderTop,
    },
    data() {
        return {
            title: "消息",
            backVisible: false,
            itemData: [
                {
                    img: require("@/assets/yq/message-sign.png"),
                    title: "签约提醒",
                    content: "合合同签约待签约，签约成功，失败，逾期，到期等提醒",
                    type: "signModule",
                    url: "/messages"
                },
                {
                    img: require("@/assets/yq/message-contarct.png"),
                    title: "合同提醒",
                    content: "合同到期，专属顾问变更等提醒",
                    type: "contractModule",
                    url: "/contractMessages"
                },
                {
                    img: require("@/assets/yq/message-notice.png"),
                    title: "通知公告",
                    content: "平台通知，公告，系统提醒",
                    type: "systemModule",
                    url: "/systemMessages"
                }
            ],
            noticeMerge: [],
            isPullRefresh: false,
        }
    },
    methods: {
        goMessageList(idx) {
            let title = this.itemData[idx].title;
            let type = this.itemData[idx].type;
            let url = this.itemData[idx].url;
            this.$router.push({
                path: url,
                query: {
                    type,
                    title
                }
            })
        },
        loadData() {
            getNoticeMerge().then(res => {
                let { status } = res;
                if (status) {
                    this.noticeMerge = res.data;
                }
            });
        },
        onRefresh() {
            setTimeout(() => {
                this.loadData();
                this.isPullRefresh = false;
            }, 800)

        }
    },
    created() {
        this.loadData();

    },
}
</script>

<style lang="scss" scoped>
div {
    box-sizing: border-box;
}
.message-bar {
    padding-top: 50px;
    background: #fff;
    height: 100vh;
}
.message-bar .content {
    padding: 10px 20px 0 20px;
}
.content .item {
    height: 80px;
    border-bottom: 1px solid #edf1f4;
    padding-top: 14px;
}
.content .item .message {
    height: 50px;
}
.content .item .message .img {
    position: relative;
}
.content .item .message .red-spot {
    position: absolute;
    top: 0;
    right: 0;
    width: 19px;
    height: 19px;
    line-height: 19px;
    background: #fe5b42;
    border-radius: 50%;
    font-size: 12px;
    text-align: center;
    color: #fff;
}
.content .item .message .img img {
    height: 50px;
    width: 50px;
}
.content .item .message .text-box {
    margin-left: 14px;
    width: 274.5px;
}
.content .item .message h3 {
    font-weight: 600;
}
.content .item .message h4 {
    margin-top: 5px;
    font-size: 13px;
    color: #999;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>